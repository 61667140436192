import '../scss/main.scss';

Array.from(document.querySelectorAll('form[data-on-change="submit"]')).forEach((el) => {
    if (!(el instanceof HTMLFormElement)) {
        return;
    }

    el.addEventListener('change', () => {
        el.submit();
    });
});
